import React, { useState, useEffect } from "react";
import axios from "axios";
// import TrackVisibility from "react-on-screen";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";
import Layout from "../components/Layout";
// import Progress from "../components/Progress";
import Resume from "../components/Resume";

function Resumes() {
  const [languages, setLanguages] = useState([]);
  const [webSkills, setWebSkills] = useState([]);
  const [databaseSkills, setDatabaseSkills] = useState([]);
  const [testSkills, setTestSkills] = useState([]);
  const [gameSkills, setGameSkills] = useState([]);
  const [mobileSkills, setMobileSkills] = useState([]);
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);

  useEffect(() => {
    axios.get("/api/languages").then((response) => {
      setLanguages(response.data);
    });
    axios.get("/api/webSkills").then((response) => {
      setWebSkills(response.data);
    });
    axios.get("/api/databaseSkills").then((response) => {
      setDatabaseSkills(response.data);
    });
    axios.get("/api/testSkills").then((response) => {
      setTestSkills(response.data);
    });
    axios.get("/api/gameSkills").then((response) => {
      setGameSkills(response.data);
    });
    axios.get("/api/mobileSkills").then((response) => {
      setMobileSkills(response.data);
    });
    axios.get("/api/experience").then((response) => {
      setWorkingExperience(response.data.workingExperience);
      setEducationExperience(response.data.educationExperience);
    });
  }, []);

  return (
    <Layout title="Temisan Iwere">
      <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse custom-skills">
              <div className="custom-skill">
                <p className="skills-section">Languages: &nbsp;&nbsp;</p>
                {languages.map((language) => (
                  // <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  //   <Progress title={skill.title} percentage={skill.value} />
                  // </TrackVisibility>
                  <button
                    style={{ cursor: "default" }}
                    key={language.title}
                    disabled
                    className="custom-skill-btn"
                  >
                    {language.title}
                  </button>
                ))}
              </div>
              <div className="custom-skill">
                <p className="skills-section">Web Development:</p>
                {webSkills.map((webSkill) => (
                  // <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  //   <Progress title={skill.title} percentage={skill.value} />
                  // </TrackVisibility>
                  <button
                    style={{ cursor: "default" }}
                    key={webSkill.title}
                    disabled
                    className="custom-skill-btn"
                  >
                    {webSkill.title}
                  </button>
                ))}
              </div>
              <div className="custom-skill">
                <p className="skills-section">Mobile Development:</p>
                {mobileSkills.map((mobileSkill) => (
                  // <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  //   <Progress title={skill.title} percentage={skill.value} />
                  // </TrackVisibility>
                  <button
                    style={{ cursor: "default" }}
                    key={mobileSkill.title}
                    disabled
                    className="custom-skill-btn"
                  >
                    {mobileSkill.title}
                  </button>
                ))}
              </div>
              <div className="custom-skill">
                <p className="skills-section">Game Development:</p>
                {gameSkills.map((gameSkill) => (
                  // <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  //   <Progress title={skill.title} percentage={skill.value} />
                  // </TrackVisibility>
                  <button
                    style={{ cursor: "default" }}
                    key={gameSkill.title}
                    disabled
                    className="custom-skill-btn"
                  >
                    {gameSkill.title}
                  </button>
                ))}
              </div>
              <div className="custom-skill">
                <p className="skills-section">Database:</p>
                {databaseSkills.map((databaseSkill) => (
                  // <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  //   <Progress title={skill.title} percentage={skill.value} />
                  // </TrackVisibility>
                  <button
                    style={{ cursor: "default" }}
                    key={databaseSkill.title}
                    disabled
                    className="custom-skill-btn"
                  >
                    {databaseSkill.title}
                  </button>
                ))}
              </div>
              <div className="custom-skill">
                <p className="skills-section">Automation Testing:</p>
                {testSkills.map((testSkill) => (
                  // <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  //   <Progress title={skill.title} percentage={skill.value} />
                  // </TrackVisibility>
                  <button
                    style={{ cursor: "default" }}
                    key={testSkill.title}
                    disabled
                    className="custom-skill-btn"
                  >
                    {testSkill.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          <Smalltitle title="Work Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map((workingExp) => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"></div>
          <Smalltitle title="Education" icon="book" />
          <div className="mi-resume-wrapper">
            {educationExperience.map((educatonExp) => (
              <Resume key={educatonExp.id} resumeData={educatonExp} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resumes;
