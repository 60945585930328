import Mock from "../mock";

const database = {
  information: {
    name: "Temisan Iwere",
    aboutContent:
      "I am a Software Engineer located in Toronto, focused mainly on full stack web development and automation testing with an interest in DevOps, Cloud Technologies & Artificial Intelligence",
    aboutContentSub:
      "I’m passionate about increasing diversity and inclusion in the tech industry by providing the underrepresented with access to information, education, networking and mentorship opportunities",
    age: "",
    phone: "",
    nationality: "",
    language: "",
    email: "temisaniwere@yahoo.com",
    address: "",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "https://www.facebook.com/misan.iwere",
      twitter: "https://twitter.com/misan_iwere",
      pinterest: "",
      behance: "",
      linkedin: "https://ca.linkedin.com/in/temisaniwere",
      dribbble: "",
      github: "https://github.com/c3iweret",
    },
    brandImage: "/images/biopic.jpg",
    aboutImage: "/images/about.jpg",
    aboutImageLg: "/images/about.jpg",
    cvfile: "/files/resume.pdf",
  },
  services: [
    {
      title: "Web Design",
      icon: "color-pallet",
      details:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    },
    {
      title: "Web Development",
      icon: "code",
      details:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    },
    {
      title: "Mobile Application",
      icon: "mobile",
      details:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  languages: [
    {
      title: "Java",
      value: 65,
    },
    {
      title: "Python",
      value: 65,
    },
    {
      title: "C",
      value: 65,
    },
    {
      title: "C#",
      value: 65,
    },
  ],
  webSkills: [
    {
      title: "React",
      value: 65,
    },
    {
      title: "Redux",
      value: 65,
    },
    {
      title: "NodeJS",
      value: 65,
    },
    {
      title: "Express",
      value: 65,
    },
    {
      title: "JavaScript",
      value: 65,
    },
    {
      title: "HTML",
      value: 65,
    },
    {
      title: "CSS",
      value: 65,
    },
  ],
  databaseSkills: [
    {
      title: "MongoDB",
      value: 65,
    },
    {
      title: "Firebase",
      value: 65,
    },
    {
      title: "PSQL",
      value: 65,
    },
  ],
  gameSkills: [
    {
      title: "Unity",
      value: 65,
    },
  ],
  mobileSkills: [
    {
      title: "Swift",
      value: 65,
    },
    {
      title: "React Native",
      value: 65,
    },
  ],
  testSkills: [
    {
      title: "TDD",
      value: 65,
    },
    {
      title: "Selenium",
      value: 65,
    },
    {
      title: "Jest",
      value: 65,
    },
    {
      title: "Chai",
      value: 65,
    },
    {
      title: "Mocha",
      value: 65,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Death Mates",
      subtitle:
        "A free-for-all multiplayer 3D racing side-scrolling platformer. In Death Mates, players race to the finish line while altering the level by placing power-ups and traps to boost or sabotage each other. The goal is to get to the finish line at all costs.",
      imageUrl: "/images/DeathMates.png",
      largeImageUrl: ["/images/DeathMates.png"],
      url: "https://github.com/c3iweret/Death-Mates",
    },
    {
      id: 2,
      title: "Settle",
      subtitle:
        "A web application that utilizes a chatbot built with the IBM Watson API to match you to an immigrant mentor or mentee in a host country.",
      imageUrl: "/images/settle.png",
      largeImageUrl: ["/images/settle.png"],
      url: "https://github.com/c3iweret/SettleWeb",
    },
    {
      id: 3,
      title: "Smart Sponsor",
      subtitle:
        "A decentralized web application that uses blockchain to streamline the sponsorship process for content creators.",
      imageUrl: "/images/smartsponsor.png",
      largeImageUrl: ["/images/smartsponsor.png"],
      url: "https://github.com/c3iweret/SmartSponsor",
    },
    {
      id: 4,
      title: "Drink Easy",
      subtitle:
        "A platform that allows you to virtually order drinks at a bar with your phone using a built-in Interac payment service. Integrated with the web version for servers.",
      imageUrl: "/images/drinkeasy.png",
      largeImageUrl: ["/images/drinkeasy.png"],
      url: "https://github.com/c3iweret/DrinkEasy",
    },
    {
      id: 5,
      title: "Arc Angel",
      subtitle:
        "ArcAngel is an AI chatbot that can give you a possible diagnosis given a list of symptoms. You can communicate with ArcAngel via the web application through text or speech.",
      imageUrl: "/images/arcangel.png",
      largeImageUrl: ["/images/arcangel.png"],
      url: "https://github.com/c3iweret/ArcAngel",
    },
    {
      id: 6,
      title: "Cookie Hour",
      subtitle:
        "A virtual office hour booking/scheduling tool for students & professors",
      imageUrl: "/images/cookiehour.png",
      largeImageUrl: ["/images/cookiehour.png"],
      url: "https://github.com/c3iweret/CookieHour",
    },
    {
      id: 7,
      title: "Fitness Stack",
      subtitle:
        "A platform that provides users with utilities to help monitor and improve their fitness with features like fitness calendar, fitness-related news feed, personalized user recipes, etc",
      imageUrl: "/images/fitness.png",
      largeImageUrl: ["/images/fitness.png"],
      url: "https://github.com/c3iweret/Fitness-Dashboard",
    },
    {
      id: 8,
      title: "F0t0",
      subtitle:
        "An image repository that allows users upload images and search by caption, tag or user",
      imageUrl: "/images/foto.png",
      largeImageUrl: ["/images/foto.png"],
      url: "https://sleepy-ridge-02062.herokuapp.com",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 0,
        year: "Nov 2020 - Present",
        position: "Senior Software Engineer",
        company: "BenchSci",
        details: "",
      },
      {
        id: 1,
        year: "July 2019 - Nov 2020",
        position: "Associate Software Engineer",
        company: "Publicis Sapient",
        details: "",
      },
      {
        id: 2,
        year: "Jan 2019 - Present",
        position: "Founder & Advisory Director",
        company: "NSBEHacks UofT",
        details:
          "The first black student-run hackathon in Canada organized by the National Society of Black Engineers UofT",
      },
      {
        id: 3,
        year: "Jan - June 2019",
        position: "Technology Educator",
        company: "Tech Spark",
        details:
          "Canada's first tech and design school committed to empowering children of colour, girls, women and teachers through innovative education",
      },
      {
        id: 4,
        year: "Sept 2018 - Jan 2019",
        position: "Web Developer",
        company: "UofT Entrepreneurship Hatchery",
        details: "",
      },
      {
        id: 5,
        year: "April 2017 - Aug 2018",
        position: "Automation Test Engineer Intern",
        company: "Intact Financial Corporation",
        details: "",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2013 - 2019",
        graduation: "Honours Bachelor of Science",
        university: "University of Toronto",
        details: "Computer Science Specialist Program",
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {},
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/languages").reply((config) => {
  const response = database.languages;
  return [200, response];
});

Mock.onGet("/api/webSkills").reply((config) => {
  const response = database.webSkills;
  return [200, response];
});

Mock.onGet("/api/databaseSkills").reply((config) => {
  const response = database.databaseSkills;
  return [200, response];
});

Mock.onGet("/api/testSkills").reply((config) => {
  const response = database.testSkills;
  return [200, response];
});

Mock.onGet("/api/gameSkills").reply((config) => {
  const response = database.gameSkills;
  return [200, response];
});

Mock.onGet("/api/mobileSkills").reply((config) => {
  const response = database.mobileSkills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
